import * as React from "react";

import { Button } from "tabler-react";

export default class HeaderUnLoggedUser extends React.Component {
  render() {
    return (
      <Button
        size="sm"
        color="primary"
        RootComponent="a"
        href="https://hirexl.ai/"
      >
        Check our Application Tracking System
      </Button>
    );
  }
}
